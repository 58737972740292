// extracted by mini-css-extract-plugin
export var navigation = "Works-module--navigation--3a6lz";
export var portfolio = "Works-module--portfolio--LR7W1";
export var portfolio__footer__button = "Works-module--portfolio__footer__button--cF4JL";
export var portfolio__footer__button_more = "Works-module--portfolio__footer__button_more--RBrtL";
export var portfolio__header = "Works-module--portfolio__header--lXZL2";
export var portfolio__header__button = "Works-module--portfolio__header__button--uUAfd";
export var portfolio__header__button__text = "Works-module--portfolio__header__button__text--5vMcS";
export var portfolio__header__title = "Works-module--portfolio__header__title--4-uRf";
export var portfolio__main = "Works-module--portfolio__main--GXxe1";
export var portfolio__main__container = "Works-module--portfolio__main__container--joqsj";